var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.emitNewStock)}}},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 w-full"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('stock.form.edit.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"mt-4",attrs:{"disabled":_vm.isEditStock,"label":_vm.$t('stock.form.edit.type')},model:{value:(_vm.newStock.type),callback:function ($$v) {_vm.$set(_vm.newStock, "type", $$v)},expression:"newStock.type"}},_vm._l((_vm.optionsType),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.value,"text":item.text}})}),1),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('stock.form.edit.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":_vm.$t('stock.form.edit.title'),"name":"title"},model:{value:(_vm.newStock.title),callback:function ($$v) {_vm.$set(_vm.newStock, "title", $$v)},expression:"newStock.title"}}),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('stock.form.edit.description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":_vm.$t('stock.form.edit.description'),"name":"description"},model:{value:(_vm.newStock.description),callback:function ($$v) {_vm.$set(_vm.newStock, "description", $$v)},expression:"newStock.description"}}),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.newStock.type==='gadget')?_c('ValidationProvider',{attrs:{"rules":{ regex: /^\d+(?:\.\d+)?$/ },"name":_vm.$t('stock.form.edit.unitPrice')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":_vm.$t('stock.form.edit.unitPrice'),"name":"unitPrice"},on:{"input":_vm.checkPriceInput},model:{value:(_vm.newStock.unitPrice),callback:function ($$v) {_vm.$set(_vm.newStock, "unitPrice", $$v)},expression:"newStock.unitPrice"}}),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),(_vm.newStock.type==='gadget')?_c('ValidationProvider',{attrs:{"name":_vm.$t('stock.form.edit.company'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-select',{staticClass:"mt-4",attrs:{"placeholder":"Companies","multiple":"","label":_vm.$t('stock.form.edit.company')},model:{value:(_vm.newStock.companies),callback:function ($$v) {_vm.$set(_vm.newStock, "companies", $$v)},expression:"newStock.companies"}},_vm._l((_vm.optionsCompany),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.value,"text":item.text}})}),1),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('stock.form.edit.storageArea')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-4",attrs:{"label":_vm.$t('stock.form.edit.storageArea'),"name":"storageArea"},model:{value:(_vm.newStock.storageArea),callback:function ($$v) {_vm.$set(_vm.newStock, "storageArea", $$v)},expression:"newStock.storageArea"}}),_c('span',{staticClass:"error-class"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"mt-10"},[_c('upload-avatar',{attrs:{"user":_vm.newStock},on:{"update:avatar":_vm.avatarUpdate,"uploaded:avatar":_vm.avatarUploaded}})],1),_c('div',{staticClass:"mt-8 flex flex-wrap items-center justify-end"},[_c('vs-button',{staticClass:"ml-auto mt-2",attrs:{"button":"submit","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('stock.form.input.submit')))])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }