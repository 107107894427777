<template>
  <ValidationObserver v-slot="{ invalid, handleSubmit }">
    <form @submit.prevent="handleSubmit(emitNewStock)">
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <!-- type -->
          <ValidationProvider :name="$t('stock.form.edit.type')" rules="required" v-slot="{ errors }">
            <vs-select :disabled="isEditStock" :label="$t('stock.form.edit.type')"
              v-model="newStock.type"
              class="mt-4"
            >
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in optionsType" />
            </vs-select>
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
          <!-- title -->
          <ValidationProvider :name="$t('stock.form.edit.title')" rules="required" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('stock.form.edit.title')" v-model="newStock.title" name="title" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
          <!-- description -->
          <ValidationProvider :name="$t('stock.form.edit.description')" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('stock.form.edit.description')" v-model="newStock.description" name="description" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
          <!-- unitprice -->
          <ValidationProvider :rules="{ regex: /^\d+(?:\.\d+)?$/ }" v-if="newStock.type==='gadget'" :name="$t('stock.form.edit.unitPrice')" v-slot="{ errors }">
            <vs-input @input="checkPriceInput" class="w-full mt-4" :label="$t('stock.form.edit.unitPrice')" v-model="newStock.unitPrice" name="unitPrice" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
          <!-- company -->
          <ValidationProvider v-if="newStock.type==='gadget'" :name="$t('stock.form.edit.company')" rules="required" v-slot="{ errors }">
            <vs-select placeholder="Companies" multiple :label="$t('stock.form.edit.company')"
              v-model="newStock.companies"
              class="mt-4"
            >
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in optionsCompany" />
            </vs-select>
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
          <!-- storage area -->
          <ValidationProvider :name="$t('stock.form.edit.storageArea')" v-slot="{ errors }">
            <vs-input class="w-full mt-4" :label="$t('stock.form.edit.storageArea')" v-model="newStock.storageArea" name="storageArea" />
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="mt-10">
        <upload-avatar :user="newStock" @update:avatar="avatarUpdate" @uploaded:avatar="avatarUploaded"/>
      </div>
      <div class="mt-8 flex flex-wrap items-center justify-end">
        <vs-button button="submit" class="ml-auto mt-2" :disabled="invalid">{{ $t('stock.form.input.submit') }}</vs-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import UploadAvatar from '@/views/apps/user/user-form/UploadAvatar'

// Store Module
import moduleStockMixin from '@/store/stock/moduleStockMixin'

export default {
  components: {
    UploadAvatar
  },
  mixins: [moduleStockMixin],
  props: {
    newStock: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      optionsCompany: [
        ...(!this.$acl.check('stockEncevo') || this.$acl.check('stockEnovos')) ? [{ text:'Leoenergy', value:'leoenergy' }] : [],
        ...(!this.$acl.check('stockEncevo') || this.$acl.check('stockEnovos')) ? [{ text:'Nordenergie', value:'nordenergie' }] : [],
        ...(!this.$acl.check('stockEncevo') || this.$acl.check('stockEnovos')) ? [{ text:'Steinergy', value:'steinergy' }] : [],
        ...this.$acl.check('stockEnovos') ? [{ text:'Enovos', value:'enovos' }] : [],
        ...this.$acl.check('stockEncevo') ? [{ text:'Encevo', value:'encevo' }] : []
      ],
      optionsType: [
        {
          text:'Gadget',
          value:'gadget'
        },
        ...this.$acl.check('stockEnovos') ? [{ text:'Brochure', value:'brochure' }] : [],
        ...this.$acl.check('stockEnovos') ? [{ text:'Materiel', value:'materiel' }] : []
      ],
      isModified: false,
      fileAvatar: null
    }
  },
  computed: {
    isEditStock () {
      return this.$route.name === 'stock-edit'
    }
  },
  methods: {
    avatarUpdate (file)  {
      this.fileAvatar = file
      if (file) {
        const reader = new FileReader()
        reader.onload = e => {
          this.$set(this.newStock, 'tempAvatar', e.target.result)
          this.$set(this.newStock, 'image', this.fileAvatar)
          this.$set(this.newStock, 'fileAvatar', this.fileAvatar)
        }
        reader.readAsDataURL(file)
      } else {
        this.$set(this.newStock, 'tempAvatar', null)
        this.$set(this.newStock, 'image', null)
        this.$set(this.newStock, 'fileAvatar', null)
      }
    },
    avatarUploaded (mediaObject) {
      this.isModified = true
      this.$set(this.newStock, 'image', mediaObject)
      this.$set(this.newStock, 'fileAvatar', mediaObject)
    },

    emitNewStock () {
      this.$emit('getFileAvatar', this.fileAvatar)
      this.$emit('getInputValues', this.newStock)
    },
    checkPriceInput (newValue) {
      if (newValue.includes(',')) {
        newValue = newValue.replace(',', '.')
        this.newStock.unitPrice = newValue
      }
    }
  }
}
</script>

<style scoped>

</style>
